import axios from "axios";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export default axios.create({
  withCredentials: false,
  baseURL: "https://a.justervesenet.no:444/api/tilda/",
  headers: {
    "Content-type": "application/json"
  }
});