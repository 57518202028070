import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Rapport from './pages/rapport';
import Home from './pages/home';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rapport/:orgnr" element={<Rapport />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
export default App