import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link, CircularProgress, AppBar, Backdrop, ListItem, ListItemAvatar, IconButton, ListItemText, Avatar, Card, CardContent, List } from '@mui/material';
import { useEffect, useState } from 'react';
import api from '../components/api';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { deepOrange, green, grey } from '@mui/material/colors';
import InventoryIcon from '@mui/icons-material/Inventory';
import LockClockIcon from '@mui/icons-material/LockClock';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const defaultTheme = createTheme();


export default function Rapport() {
    const { orgnr } = useParams();
    const [isLoading, setisLoading] = useState(true);
    const [tildaData, settildaData] = useState([]);
    const [tildaDataRapporter, settildaDataRapporter] = useState(0);
    const [tildaDataDetaljer, settildaDataDetaljer] = useState([]);

    const handleGetRapporter = (rid: number) => {
        settildaDataRapporter(rid);
        tildaData.map(({ value }: any, index: number) => {
            if (index === rid) {
                var enhetsdata = [];
                try {
                    enhetsdata = JSON.parse(value);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
                settildaDataDetaljer(enhetsdata.tilsynsrapporter);
                console.log(enhetsdata.tilsynsrapporter);
            }
        })
    }


    useEffect(() => {
        api.get("/tilsynsrapport/" + orgnr)
            .then((response: any) => {
                const tildadata = response.data;
                settildaData(tildadata);
                console.log(response.data);
                setisLoading(false);
            })
            .catch((e: any) => {
                setisLoading(false);
                console.log(e);
            });
    }, []);

    useEffect(() => {
    }, [tildaDataDetaljer]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute">
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Tilda Web
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    {!isLoading ?
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={2}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <List
                                        component="nav"
                                    >

                                        {tildaData && tildaData.map(({ evidenceValueName, source, timestamp, value }: any, index: number) => {
                                            if (source == "Enhetsregisteret") {
                                                var enhetsinfo = [];
                                                try {
                                                    enhetsinfo = JSON.parse(value);
                                                } catch (error) {
                                                    console.error("Error parsing JSON:", error);
                                                }
                                                return <div>
                                                    <Typography>
                                                        {enhetsinfo.tildaenhetNavn}
                                                    </Typography>
                                                    <Typography>
                                                        {enhetsinfo.tildaenhet}
                                                    </Typography>
                                                    <hr></hr>
                                                </div>
                                            }
                                            else {
                                                var tilsynsinfo = [];
                                                try {
                                                    tilsynsinfo = JSON.parse(value);
                                                } catch (error) {
                                                    console.error("Error parsing JSON:", error);
                                                }
                                                var kilde = source;
                                                if (source === "974761211") {
                                                    kilde = "Arbeidstilsynet";
                                                }
                                                if (source === "971203420") {
                                                    kilde = "Fiskeridirektoratet";
                                                }
                                                if (source === "974761394") {
                                                    kilde = "Statens helsetilsyn";
                                                }
                                                if (source === "874761192") {
                                                    kilde = "Justervesenet";
                                                }
                                                if (source === "999601391") {
                                                    kilde = "Miljødirektoratet";
                                                }
                                                if (source === "974762994") {
                                                    kilde = "Statsforvalteren i Agder";
                                                }
                                                if (source === "974761645") {
                                                    kilde = "Statsforvalteren i Innlandet";
                                                }
                                                if (source === "974764067") {
                                                    kilde = "Statsforvalteren i Møre og Romsdal";
                                                }
                                                if (source === "974764687") {
                                                    kilde = "Statsforvalteren i Nordland";
                                                }
                                                if (source === "974761319") {
                                                    kilde = "Statsforvalteren i Oslo og Viken";
                                                }
                                                if (source === "974763230") {
                                                    kilde = "Statsforvalteren i Rogaland";
                                                }
                                                if (source === "967311014") {
                                                    kilde = "Statsforvalteren i Troms og Finnmark";
                                                }
                                                if (source === "974764350") {
                                                    kilde = "Statsforvalteren i Trøndelag";
                                                }
                                                if (source === "974762501") {
                                                    kilde = "Statsforvalteren i Vestfold og Telemark";
                                                }
                                                if (source === "974760665") {
                                                    kilde = "Statsforvalteren i Vestland";
                                                }
                                                var rapporter = tilsynsinfo.tilsynsrapporter;

                                                return <ListItem
                                                    className="menuhover"
                                                    onClick={() => handleGetRapporter(index)}
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete">
                                                            {(tilsynsinfo.status === "ok") ?
                                                                (rapporter) ?
                                                                    (rapporter?.length !== 0) ?
                                                                        <Avatar sx={{ bgcolor: green[500] }}>
                                                                            {rapporter?.length}
                                                                        </Avatar>
                                                                        :
                                                                        <Avatar sx={{ bgcolor: grey[500] }}>
                                                                            0
                                                                        </Avatar>

                                                                    :
                                                                    <Avatar sx={{ bgcolor: grey[500] }}>
                                                                        0
                                                                    </Avatar>
                                                                :
                                                                <Avatar sx={{ bgcolor: deepOrange[900] }}>
                                                                    X
                                                                </Avatar>
                                                            }
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        {(tilsynsinfo.status == "ok") ?
                                                            <Avatar sx={{ bgcolor: green[500] }}>
                                                                <CheckIcon />
                                                            </Avatar>
                                                            :
                                                            <Avatar sx={{ bgcolor: deepOrange[900] }}>
                                                                <WarningAmberIcon />
                                                            </Avatar>
                                                        }
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={kilde}
                                                        secondary={(tilsynsinfo.status === "ok") ? source : <div>{source} <br /><Typography variant="caption" sx={{ fontSize: 10, color: deepOrange[900] }}>{tilsynsinfo.statustekst}</Typography></div>}
                                                    />
                                                </ListItem>


                                                // return <span key={"tsrc" + index}>{source}
                                                // </span>
                                            }
                                        })
                                        }
                                    </List>
                                </Paper>
                            </Grid>
                            <Grid item xs={10}>
                                {((tildaDataRapporter) && (tildaDataRapporter !== 0)) &&
                                    tildaDataDetaljer.map(({ ansvarligTilsynsmyndighet, tilsynsegenskaper, kontrolladresser, utfoerteTilsynsaktiviteter, bruddOgReaksjoner }: any, index: number) => {
                                        var dato = "Ukjent";
                                        if (utfoerteTilsynsaktiviteter) {
                                            dato = utfoerteTilsynsaktiviteter[0]?.startdatoForTilsynsaktivitet?.substr(0, 10).split("-").reverse().join(".");
                                        }
                                        var tilsynstema = "Ukjent";
                                        if (tilsynsegenskaper?.tilsynstema) {
                                            tilsynstema = tilsynsegenskaper.tilsynstema;
                                        }
                                        var tilsynsid = "Ukjent";
                                        if (tilsynsegenskaper?.internTilsynsid) {
                                            tilsynsid = tilsynsegenskaper.internTilsynsid;
                                        }
                                        var tilsynsstatus = "Ukjent";
                                        if (tilsynsegenskaper?.tilsynsstatus) {
                                            tilsynsstatus = tilsynsegenskaper.tilsynsstatus;
                                        }
                                        var uanmeldttilsyn = "Ukjent";
                                        if (tilsynsegenskaper?.uanmeldttilsyn) {
                                            uanmeldttilsyn = tilsynsegenskaper.uanmeldttilsyn;
                                        }
                                        var kontrolladresse = "Ukjent";
                                        if (kontrolladresser?.length > 0) {
                                            kontrolladresse = kontrolladresser[0]?.adressenavn + ", " + kontrolladresser[0]?.postnummer + " (" + kontrolladresser[0]?.poststedsnavn + ")";
                                        }


                                        var kilde = ansvarligTilsynsmyndighet;
                                        if (ansvarligTilsynsmyndighet === "974761211") {
                                            kilde = "Arbeidstilsynet";
                                        }
                                        if (ansvarligTilsynsmyndighet === "971203420") {
                                            kilde = "Fiskeridirektoratet";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974761394") {
                                            kilde = "Statens helsetilsyn";
                                        }
                                        if (ansvarligTilsynsmyndighet === "874761192") {
                                            kilde = "Justervesenet";
                                        }
                                        if (ansvarligTilsynsmyndighet === "999601391") {
                                            kilde = "Miljødirektoratet";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974762994") {
                                            kilde = "Statsforvalteren i Agder";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974761645") {
                                            kilde = "Statsforvalteren i Innlandet";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974764067") {
                                            kilde = "Statsforvalteren i Møre og Romsdal";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974764687") {
                                            kilde = "Statsforvalteren i Nordland";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974761319") {
                                            kilde = "Statsforvalteren i Oslo og Viken";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974763230") {
                                            kilde = "Statsforvalteren i Rogaland";
                                        }
                                        if (ansvarligTilsynsmyndighet === "967311014") {
                                            kilde = "Statsforvalteren i Troms og Finnmark";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974764350") {
                                            kilde = "Statsforvalteren i Trøndelag";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974762501") {
                                            kilde = "Statsforvalteren i Vestfold og Telemark";
                                        }
                                        if (ansvarligTilsynsmyndighet === "974760665") {
                                            kilde = "Statsforvalteren i Vestland";
                                        }

                                        return <Card sx={{ minWidth: 275 }}>
                                            <CardContent>
                                                <Typography variant="overline" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    <b>{dato}</b> - {kilde}
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {tilsynstema}
                                                </Typography>
                                                <Grid container pt={2}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="caption" color="text.secondary" className='typeicon' gutterBottom pt={1}>
                                                            <InventoryIcon className='body1icon' /> Referanse
                                                        </Typography>
                                                        <Typography variant="body1" color="text.primary" className='potypeicon' gutterBottom>
                                                            {tilsynsid}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="caption" color="text.secondary" className='typeicon' gutterBottom pt={1}>
                                                            <CalendarMonthIcon className='body1icon' /> Startdato
                                                        </Typography>
                                                        <Typography variant="body1" color="text.primary" className='potypeicon' gutterBottom>
                                                            {dato}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="caption" color="text.secondary" className='typeicon' gutterBottom pt={1}>
                                                            <LockClockIcon className='body1icon' /> Tilsynsstatus
                                                        </Typography>
                                                        <Typography variant="body1" color="text.primary" className='potypeicon' gutterBottom>
                                                            {tilsynsstatus}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="caption" color="text.secondary" className='typeicon' gutterBottom pt={1}>
                                                            <HomeIcon className='body1icon' /> Kontrolladresse
                                                        </Typography>
                                                        <Typography variant="body1" color="text.primary" className='potypeicon' gutterBottom>
                                                            {kontrolladresse}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="caption" color="text.secondary" className='typeicon' gutterBottom pt={1}>
                                                            <SupervisedUserCircleIcon className='body1icon' /> Uanmeldt tilsyn
                                                        </Typography>
                                                        <Typography variant="body1" color="text.primary" className='potypeicon' gutterBottom>
                                                            {uanmeldttilsyn}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" pt={2}>
                                                            Brudd og reaksjoner ({bruddOgReaksjoner?.length})
                                                        </Typography>
                                                        <Card variant="outlined">
                                                            <CardContent>
                                                                {
                                                                    ((bruddOgReaksjoner?.length !== 0) && (bruddOgReaksjoner)) ?
                                                                        bruddOgReaksjoner.map(({ alvorsgrad, bruddOgReaksjonsreferanse, lovparagraf, reaksjonsdato, utredningAvBruddOgReaksjon }: any, indexbr: number) => {
                                                                            return <div><Typography>
                                                                                #{bruddOgReaksjonsreferanse} - {reaksjonsdato?.substr(0, 10).split("-").reverse().join(".")} - {alvorsgrad?.utmaaltReaksjonstype} - {utredningAvBruddOgReaksjon}
                                                                            </Typography>
                                                                                <Typography variant="caption">
                                                                                    {lovparagraf}
                                                                                </Typography>
                                                                            </div>
                                                                        })
                                                                        :
                                                                        <div>Ingen brudd / reaksjoner</div>
                                                                }
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>

                                            </CardContent>
                                        </Card>
                                    }
                                    )}
                            </Grid>
                        </Grid>
                        :
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    }
                </Box>
            </Box>
        </ThemeProvider>
    );
}